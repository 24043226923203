const Enumify = require('enumify').Enumify
const CommonEnvEnum = require('../common/CommonEnvEnum')
const CompileModeEnum = require('../common/CompileModeEnum')
const ProjectLevelEnum = require('../project/ProjectLevelEnum')
const ModuleFederationServerEnum = require('./ModuleFederationServerEnum')

class ModuleFederationServerPortEnum extends Enumify {
  static PRODUCTION_DEV = new ModuleFederationServerPortEnum({
    key: CommonEnvEnum.PRODUCTION_DEV.key,
    default: CommonEnvEnum.PRODUCTION_DEV.protocol + 'm.' + CommonEnvEnum.PRODUCTION_DEV.address,
    port: {
      [ProjectLevelEnum.MAIN.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'v3.www.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.MICRO.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'v3.www.' + CommonEnvEnum.PRODUCTION_DEV.address,
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'm.' + CommonEnvEnum.PRODUCTION_DEV.address,
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'im.' + CommonEnvEnum.PRODUCTION_DEV.address,
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'seller.' + CommonEnvEnum.PRODUCTION_DEV.address,
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'co.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.LAYOUT.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'co.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.FRAMEWORK.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'co.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.COMPONENT.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'co.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.BUSINESS.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'dashboard.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
      [ProjectLevelEnum.BASICS.key]: [
        CommonEnvEnum.PRODUCTION_DEV.protocol + 'dashboard.' + CommonEnvEnum.PRODUCTION_DEV.address,
      ],
    },
  })
  static PRODUCTION_TEST = new ModuleFederationServerPortEnum({
    key: CommonEnvEnum.PRODUCTION_TEST.key,
    default: CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8091',
    port: {
      [ProjectLevelEnum.MAIN.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8090',
      ],
      [ProjectLevelEnum.MICRO.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8090',
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8091',
        // CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8093',
        // CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8070',
        // CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8050',
      ],
      [ProjectLevelEnum.LAYOUT.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8055',
      ],
      [ProjectLevelEnum.FRAMEWORK.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8050',
      ],
      [ProjectLevelEnum.COMPONENT.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8055',
      ],
      [ProjectLevelEnum.BUSINESS.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8070',
      ],
      [ProjectLevelEnum.BASICS.key]: [
        CommonEnvEnum.PRODUCTION_TEST.protocol + CommonEnvEnum.PRODUCTION_TEST.address + ':8099',
      ],
    },
  })

  /*
  *
sourcedm.jinsubao.cn
sourcedm2.jinsubao.cn
sourcedm3.jinsubao.cn
sourcedm4.jinsubao.cn
sourcedm5.jinsubao.cn
sourcedm6.jinsubao.cn
sourcedm7.jinsubao.cn
sourcedm8.jinsubao.cn
sourcedm9.jinsubao.cn
sourcedm10.jinsubao.cn
*
  * */

  static PRODUCTION_PREVIEW = new ModuleFederationServerPortEnum({
    key: CommonEnvEnum.PRODUCTION_PREVIEW.key,
    // default: CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'phonedm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
    default: CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
    port: {
      [ProjectLevelEnum.MAIN.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'demo.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm10.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.MICRO.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'phonedm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'demo.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'slrdm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'admdm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'codm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm6.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm7.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm8.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm9.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm10.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.LAYOUT.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'codm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm5.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.FRAMEWORK.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'codm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm5.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.COMPONENT.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'codm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm5.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.BUSINESS.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'dashboardm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm4.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm3.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
      [ProjectLevelEnum.BASICS.key]: [
        // CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'dashboardm.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
        CommonEnvEnum.PRODUCTION_PREVIEW.protocol + 'sourcedm2.' + CommonEnvEnum.PRODUCTION_PREVIEW.address,
      ],
    },
  })
  /*
source.jinsubao.cn
source2.jinsubao.cn
source3.jinsubao.cn
source4.jinsubao.cn
source5.jinsubao.cn
source6.jinsubao.cn
source7.jinsubao.cn
source8.jinsubao.cn
source9.jinsubao.cn
source10.jinsubao.cn
* */
  static PRODUCTION_PROD = new ModuleFederationServerPortEnum({
    key: CommonEnvEnum.PRODUCTION_PROD.key,
    // default: CommonEnvEnum.PRODUCTION_PROD.protocol + 'm.' + CommonEnvEnum.PRODUCTION_PROD.address,
    default: CommonEnvEnum.PRODUCTION_PROD.protocol + 'source2.' + CommonEnvEnum.PRODUCTION_PROD.address,
    port: {
      [ProjectLevelEnum.MAIN.key]: [
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'www.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source10.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.MICRO.key]: [
        /*
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'm.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'www.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'seller.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'adm.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'co.' + CommonEnvEnum.PRODUCTION_PROD.address,*/
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'source6.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source7.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source8.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source9.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source10.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.LAYOUT.key]: [
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'co.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source6.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.FRAMEWORK.key]: [
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'co.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source6.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.COMPONENT.key]: [
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source5.' + CommonEnvEnum.PRODUCTION_PROD.address,
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'co.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.BUSINESS.key]: [
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'dashboard.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source4.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source3.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
      [ProjectLevelEnum.BASICS.key]: [
        // CommonEnvEnum.PRODUCTION_PROD.protocol + 'dashboard.' + CommonEnvEnum.PRODUCTION_PROD.address,
        CommonEnvEnum.PRODUCTION_PROD.protocol + 'source2.' + CommonEnvEnum.PRODUCTION_PROD.address,
      ],
    },
  })

  static _ = this.closeEnum() // TypeScript: Color.closeEnum()
  constructor(props) {
    super()
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props))
  }

  static getDevelopVarUrlBayPackageKey(projectName, env) {
    if (CommonEnvEnum.getProductionEnvList().includes(env)) {
      return this.getProdVarUrlByPackageKey(projectName, env)
    } else {
      const ServerEnum = ModuleFederationServerEnum.getEnumByKey(env)
      if (ServerEnum.mode === CompileModeEnum.DEVELOPMENT.key) {
        return ServerEnum.url
      } else {
        return ModuleFederationServerEnum.LOCAL.url
      }
    }
  }

  static getProdVarUrlByPackageKey(projectName, env) {
    const level = ProjectLevelEnum.getLevelByProjectName(projectName)
    let link, envPortConfig, portList

    switch (env) {
      case CommonEnvEnum.PRODUCTION_DEV.key:
        envPortConfig = ModuleFederationServerPortEnum.PRODUCTION_DEV.port
        break
      case CommonEnvEnum.PRODUCTION_TEST.key:
        envPortConfig = ModuleFederationServerPortEnum.PRODUCTION_TEST.port
        break
      case CommonEnvEnum.PRODUCTION_PREVIEW.key:
        envPortConfig = ModuleFederationServerPortEnum.PRODUCTION_PREVIEW.port
        break
      case CommonEnvEnum.PRODUCTION_PROD.key:
        envPortConfig = ModuleFederationServerPortEnum.PRODUCTION_PROD.port
        break
    }
    if (Object.keys(envPortConfig).length) {
      portList = envPortConfig[level]
    }
    if (Array.isArray(portList) && portList.length) {
      if (portList.length === 1) {
        link = portList[0]
      } else {
        let index = 0
        const t = projectName.split('_')
        if (t.length && t.length > 2) index = (t[1] + '').charCodeAt(0) % portList.length
        link = portList[index]
      }
    } else {
      switch (env) {
        case CommonEnvEnum.PRODUCTION_DEV.key:
          link = ModuleFederationServerPortEnum.PRODUCTION_DEV.default
          break
        case CommonEnvEnum.PRODUCTION_TEST.key:
          link = ModuleFederationServerPortEnum.PRODUCTION_TEST.default
          break
        case CommonEnvEnum.PRODUCTION_PREVIEW.key:
          link = ModuleFederationServerPortEnum.PRODUCTION_PREVIEW.default
          break
        case CommonEnvEnum.PRODUCTION_PROD.key:
          link = ModuleFederationServerPortEnum.PRODUCTION_PROD.default
          break
      }
    }

    return link
  }
}

module.exports = ModuleFederationServerPortEnum
